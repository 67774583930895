:where(.css-dev-only-do-not-override-1sn0ohr).ant-input-status-error:not(
    .ant-input-disabled
  ):not(.ant-input-borderless).ant-input {
  border: none !important;
}
:where(.css-dev-only-do-not-override-1sn0ohr).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border: none;
  background: none !important;
}
.ant-select-focused:where(.css-dev-only-do-not-override-1sn0ohr).ant-select:not(
    .ant-select-disabled
  ):not(.ant-select-customize-input):not(.ant-pagination-size-changer)
  .ant-select-selector {
  /* border: none !important; */
  box-shadow: none !important;
  /* background: #265BA6 !important;
  color: white !important; */
}
.ant-input {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.ant-btn {
  background: #265ba6;
  border: 1px solid #265ba6;
  border-radius: 5px;
  color: white;
  min-height: 35px;
  /* display: flex;
  align-items: center; */
  min-width: 130px;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn-default:hover,
.ant-btn-default:focus {
  background: #265ba6 !important;
  border-color: #265ba6 !important;
  color: white !important;
  min-height: 35px;
}
:where(.css-dev-only-do-not-override-1sn0ohr).ant-modal .ant-modal-footer {
  text-align: center;
}
.ant-input:placeholder-shown {
  font-size: 16px !important;
  font-weight: 500;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 16px !important;
  font-weight: 500;
}
.ant-form-item .ant-form-item-label > label {
  font-size: 16px !important;
  font-weight: 500;
}
.ant-input-status-error:not(.ant-input-disabled):not(
    .ant-input-borderless
  ).ant-input {
  font-weight: 400;
}
/* .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  border: none;
}
.ant-upload {
  display: flex;
  align-items: start !important;
  justify-content: center;
}
.ant-upload-wrapper .ant-upload-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 20px;
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item {
  padding: 0px;
  border: none;
} */

/* .ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .ant-upload-list-item-action.ant-btn-sm {
  background: none !important;
} */
.ant-form-item-control-input-content {
  /* padding-left: 12px; */

  margin-top: 0px;
}

.attatchment-input .ant-form-item-control-input-content {
  padding-left: 12px;
}
/* .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before {
  height: 100%;
  padding: 0 ;
  width: 100%;
  border-radius: 8px;
}
.ant-upload-list-item{
  padding: 0 !important;
} */
.clearBtn {
  background: #ededed;
  color: #265ba6;
  border-color: #ededed !important;
}
.clearBtn:hover {
  background: #f8f8f8 !important;
  color: #265ba6 !important ;
  border-color: #f8f8f8 !important;
}
.clearBtn:focus {
  background: #f8f8f8 !important;
  color: #265ba6 !important ;
  border-color: #f8f8f8 !important;
  box-shadow: none !important;
}
.ant-input:hover {
  border: 0.5px solid rgba(22, 32, 56, 0.5) !important;
}
:where(.css-dev-only-do-not-override-1sn0ohr).ant-input:hover,
.ant-input:focus,
:where(.css-dev-only-do-not-override-1sn0ohr).ant-input-focused {
  border: 0.5px solid rgba(22, 32, 56, 0.5) !important;
  box-shadow: none !important;
}
.ant-form-item
  .ant-form-item-label
  > label.ant-form-item-required:not(
    .ant-form-item-required-mark-optional
  )::before {
  display: none;
}
.ant-form-item .ant-form-item-label > label::after {
  visibility: visible;
  content: "*";
  color: #e42126;
}
.ant-form-item .ant-form-item-explain-error {
  font-weight: 500;
}
/* .notRequired.ant-form-item .ant-form-item-label >label::after{
  visibility: hidden;
} */
.ant-btn-icon {
  font-size: 20px;
}

@media screen and (max-width: 1400px) {
  .maintext {
    font-size: 30px;
  }
}

@media screen and (max-width: 768px) {
  .maintext {
    font-size: 20px;
  }
  .secondtext {
    font-size: 20px;
  }
  .custom-padding {
    padding: 15px 10px !important;
  }
}
@media screen and (max-width: 600px) {
  .maintext {
    font-size: 14px;
  }
  .logo{
    width: 60% !important;
  }
  .secondtext {
    font-size: 14px;
  }
}

.reset-btn {
  border-radius: 5px;
  background: #f8f8f8 !important;
  outline: none !important;
  color: #265ba6;
  border: none;
}
.reset-btn:hover,
.reset-btn:focus {
  box-shadow: none !important;
  outline: none !important;
  background: #f8f8f8 !important;
  color: #265ba6 !important;
}

.reset-btn:focus-visible {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

/* .image-upload-container {
  display: flex !important;
  flex-wrap: wrap;
  flex-direction: row;
} */

.ant-upload .ant-upload-select {
  border: 0.5px solid rgba(22, 32, 56, 0.5) !important;
}
.ant-btn-text,
.ant-btn-text:hover {
  background-color: transparent !important;
  max-width: 30px !important;
  min-width: 30px !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background: none;
}
.ant-upload-picture-card-wrapper {
  border-color: rgba(22, 32, 56, 0.5) !important;
}
.ant-upload-picture-card-wrapper .ant-upload.ant-upload-select:hover {
  border-color: #e8e8e8 !important;
}

.demo-wrapper {
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
}

.tox-tinymce {
  min-height: 200px;
  padding: 5px;

  border: 1px solid rgba(22, 32, 56, 0.5) !important;
}

/* knowledbage */
.ant-btn.ant-btn-icon-only .anticon {
  color: white !important;
}
.ant-input {
  min-height: 35px !important;
}

.b-none {
  .ant-input:hover,
  .ant-input:focus {
    border: none !important;
  }
}
.ant-input-search .ant-input-search-button {
  height: 45px;
}

.ant-pagination-item {
  /* background-color: rgb(234, 224, 224) !important; */
}

.custom-dragger {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: start;

  .ant-upload-drag {
    width: 100px;
    height: 100px;
  }
}

/* table css of listing kb */
.ant-table-cell {
  text-align: center !important;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 12px 0 !important;
  background: #265ba6 !important;
  color: white;
}
.ant-table-wrapper
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  width: 0px;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  border: 1px solid #f0f0f0;
}
.ant-form-inline {
  align-items: center;
}

.main-input .ant-input-affix-wrapper {
  padding: 0 !important;
}

.main-input .ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border: 1px solid #d9d9d9 !important;
  box-shadow: none !important;
}

.main-input .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
  width: 200px !important;
  height: auto !important;
  padding: 4px 11px !important;
}

@media (max-width: 992px) {
  .custom-width-50 {
    width: 100% !important;

    padding: 0 10px;
  }
}
.main-input {
  .ant-input {
    border: none !important;
  }
  .ant-input:hover,
  .ant-input:focus {
    border: none !important;
  }

  .ant-input-affix-wrapper {
    width: 275px !important;
    padding: 2px 10px !important;
  }

  .ant-input-affix-wrapper:hover,
  .ant-input-affix-wrapper:focus {
    border: 1px solid #d9d9d9 !important;
  }

  .ant-picker,
  .ant-picker:hover,
  .ant-picker:focus {
    padding: 8px 11px !important;
    /* border: none !important; */
    border: 1px solid #d9d9d9 !important;
    box-shadow: none !important;
  }
}
.ant-table {
  border-radius: 5px !important;
}
.custom-table {
  .ant-table-cell {
    letter-spacing: 0.5px;
    border-radius: 0 !important;
  }
}

@media (max-width: 768px) {
  .custom-table {
    .ant-table-cell {
      letter-spacing: 0.5px;
      font-size: 12px !important;
    }
    .main-custom {
      letter-spacing: 0.5px;
      font-size: 12px !important;
    }
  }
}

@media (max-width: 600px) {
  .main-input {
    width: 100%;
  }
  .main-input .ant-input-affix-wrapper {
    width: 100% !important;
  }
  .main-input .ant-select-selector {
    width: 100% !important;
    margin: 10px 0;
  }
  .main-input .ant-picker {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) and (max-width: 840px) {
  .main-input {
    width: 47%;
  }
  .main-input .ant-input-affix-wrapper {
    width: 100% !important;
  }
  .main-input .ant-select-selector {
    width: 100% !important;
    margin: 10px 0;
  }
  .main-input .ant-picker {
    width: 100%;
    margin-bottom: 10px;
  }
}


.custom-label ::after{
  visibility: hidden !important;
}